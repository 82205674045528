import React from 'react'
import Layout from '../components/layout'
import HeroRow from '../components/hero-row'
import './portfolio.css'
import { Link } from 'gatsby'
import Carousel from '../components/carousel'
import get from 'lodash/get'

export default class Portfolio extends React.Component{

  render(){
    const portfolio = get(this, 'props.data.allContentfulPortfolio.edges')

    return(
      <Layout>
        <div className="container-fluid">
          <HeroRow text="Portfolio" subtext="A portfolio of satisfied clients and their customized suites. "/>
          <div className="row row-portfolio justify-content-center">
            <div className="col-md-6">
              <div className="text-center">
                <h1>Design your dream cottage.</h1>
                <p>As a client of Home Care Suites, you are part of the process. Whether it's selecting exterior siding or interior finishes, you decide at every step of the way!</p>
                <Link to="/contact"><button className="btn btn-primary"> Get started</button></Link>
              </div>
            </div>
          </div>
          {portfolio.map(({node}, index) => 
          <div key={index} className="row row-portfolio">
            <div className="col-md-5">
              <div>
                <h1>{node.header}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: node.body.childMarkdownRemark.html
                  }}
                />
              </div>
            </div>
            <div className="col-md-7">
              <Carousel images={node.images} index={index}/>
            </div>
          </div>
          )}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query PortfolioQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPortfolio {
      edges {
        node {
          name
          header
          body {
            childMarkdownRemark {
              html
            }
          }
          images {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`